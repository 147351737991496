import React from "react";
import Menu from "./Menu";
import "./Navbar.css";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../../Assets/logo.png";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/");
  };
  return (
    <div className="navbar">
      <img
        className="logo"
        src={logo}
        alt=""
        onClick={() => {
          if (location.pathname !== "/") {
            handleNavigation();
          }
          window.scroll({ top: 0, left: 0, behavior: "smooth" });
        }}
      />
      <div className="menu-items">
        <Menu title={"About Me"} isLink={false} id={"about-me-txt"} />
        <Menu title={"Services"} isLink={false} id={"my-services-txt"} />
        <Menu title={"Portfolio"} isLink={false} id={"my-portfolio-txt"} />
        <Menu title={"Blogs"} isLink={true} />
      </div>
    </div>
  );
};

export default Navbar;
