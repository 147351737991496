import { DiCss3, DiDotnet, DiReact } from "react-icons/di";

const { FaNodeJs, FaHtml5 } = require("react-icons/fa");
const {
  SiFlutter,
  SiSpringboot,
  SiFirebase,
  SiAdobeillustrator,
} = require("react-icons/si");

export const experiences = [
  {
    index: 0,
    companyName: "Intelance",
    title: "Full Stack Developer",
    duration: "August 2023 - Present",
    roles: [
      "Crafted API endpoints using ASP.NET and documentation.",
      "Designed and created database models that represent the relation- ships of the data within a database system.",
      "Developing UI components and ensuring browser compatibility using React.js",
      "Worked on multiple features in the development of the Digital Health Platform project",
    ],
  },
  {
    index: 1,
    companyName: "eMender",
    title: "Full Stack Software Developer",
    duration: "April 2021 - Present",
    roles: [
      "Created APIs using Springboot(Java) Framework",
      "Developed Mobile App (eStota) using Flutter",
      "Published to App Store and Play Store",
    ],
  },
  {
    index: 2,
    companyName: "Green Addis Technologies",
    title: "Flutter Developer",
    duration: "May 2022 - Dec 2022",
    roles: [
      'Developing user interfaces using Flutter Framework for "Metahu Addis" V2 app',
      "API Integration with designs",
      "Testing and maintaining the app",
      "500+ new users the new iOS App",
    ],
  },
  {
    index: 3,
    companyName: "Prophecius Technologies",
    title: "Lead Software Developer",
    duration: "Sep 2022 - Present",
    roles: [
      "Full Stack Development with Node.js, React.js and Flutter",
      "Delievered 5+ apps and websites to the company's client",
      "Lead projects by keeping quality of softwares",
      "5+ satisified clients",
    ],
  },
  {
    index: 4,
    companyName: "GIZ Ethiopia",
    title: "Mini-Assitant Software Expert Intern",
    duration: "July 2019 - Sep 2019",
    roles: [
      "Support Help Desk",
      "Full management system test and data entry",
      "Quality Assurance Testing",
      "Fix and maintain Company-Issued Computers' Softwares",
    ],
  },
];
const iconStyle = { color: "inherit", fill: "inherit" };
export const skills = [
  {
    index: 0,
    icon: <DiDotnet style={iconStyle} size={50} />,
    name: "ASP.NET",
  },
  {
    index: 1,
    icon: <SiFlutter style={iconStyle} size={50} />,
    name: "Flutter",
  },
  {
    index: 2,
    icon: <FaNodeJs style={iconStyle} size={50} />,
    name: "Node.js",
  },
  {
    index: 3,
    icon: <DiReact style={iconStyle} size={50} />,
    name: "React.js",
  },
  {
    index: 4,
    icon: <SiSpringboot style={iconStyle} size={50} />,
    name: "Springboot",
  },
  {
    index: 5,
    icon: <SiFirebase style={iconStyle} size={50} />,
    name: "Firebase",
  },
  {
    index: 6,
    icon: <FaHtml5 style={iconStyle} size={50} />,
    name: "HTML",
  },
  {
    index: 7,
    icon: <DiCss3 style={iconStyle} size={50} />,
    name: "CSS",
  },
  {
    index: 8,
    icon: <SiAdobeillustrator style={iconStyle} size={50} />,
    name: "Illustrator",
  },
];
