import React from "react";
import Service from "../../Components/Service/Service";
import "./My Services.css";
import { Grid } from "@mui/material";
import { Fade } from "react-awesome-reveal";
import CustomTitle from "../../Components/Custom Title";

const servicesData = [
  {
    title: "Git Verson Control",
    description:
      "I provide services in Git version control to help teams manage and collaborate on their code efficiently. With a solid understanding of Git fundamentals, I ensure seamless code integration and version management across projects.",
  },
  {
    title: "Mobile App & Web Development",
    description:
      "As a frontend developer, I specialize in crafting visually appealing and user-friendly interfaces for web and mobile applications. My expertise lies in translating design concepts into responsive, interactive, and accessible digital experiences that engage users across various devices and platforms.",
  },
  {
    title: "Backend Development",
    description:
      "As a backend developer, I specialize in creating robust, efficient, and scalable server-side applications that power dynamic web and mobile platforms. With a strong foundation in server-side programming languages such as .NET, Java, and Node.js, I design and implement APIs and databases that ensure seamless data flow and interaction between the front end and back end of applications.",
  },
  {
    title: "UX Design",
    description:
      "As an aspiring UX designer, I am passionate about creating user-friendly and intuitive digital experiences for web and mobile applications. While I am still growing my expertise in the field, I focus on understanding user needs and translating them into effective design solutions that enhance usability and engagement.",
  },
];
const MyServices = () => {
  return (
    <Fade>
      <div id="new-serivces">
        <CustomTitle
          id="my-services"
          bgTitle="SKILLSETS"
          firstTitle="MY"
          secondTitle="EXPERTISE"
        />
        <Grid container direction="row" marginTop={"10px"} spacing={10}>
          {servicesData.map((service, index) => (
            <Grid
              display={{ lg: index % 2 === 0 ? "flex" : null, md: "initial" }}
              justifyContent={{
                lg: index % 2 === 0 ? "flex-end" : null,
                md: "flex-start",
              }}
              item
              lg={6}
            >
              <Service
                serviceTitle={service.title}
                description={service.description}
                color={"#001724"}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </Fade>
  );
};

export default MyServices;
