import React from "react";
import "./Landing Page.css";
import { BsGithub } from "react-icons/bs";
import { SiGmail } from "react-icons/si";

import { FaLinkedinIn, FaArrowRight } from "react-icons/fa";
import profileImg from "../../Assets/profile.png";
import { Box, Typography } from "@mui/material";
import PrimaryButton from "../../Components/Primary Button/Primary Button";

const LandingPage = () => {
  const scroll = (id) => {
    const section = document.getElementById(id);
    // if (section) {
    section.scrollIntoView({ behavior: "smooth" });
    // }
  };
  return (
    <Box
      sx={{
        position: "relative",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        flexWrap: "wrap",
        "@media screen and (max-width: 1000px)": {
          height: "auto",
        },
        alignItems: "center",
        padding: "1rem 5%",
      }}
    >
      <Box
        id="landing-page"
        sx={{
          width: "40%",
          textAlign: "left",
          "@media screen and (max-width: 1000px)": {
            width: "100%",
            textAlign: "center",
          },
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "var(--primary-color)",
              fontSize: "1em",
              fontWeight: "700",
              textTransform: "capitalize",
            }}
          >
            HELLO, I'M
          </Typography>
          <Typography
            sx={{
              color: "var(--primary-color)",
              fontSize: "3em",
              fontWeight: "700",
            }}
          >
            Yafet R. Alemayehu
          </Typography>
          <Typography sx={{ color: "lightgray" }}>
            I am full stack software developer who is very passionate and an
            everyday learner who focuses on delivering quality products
            <br />
            In my 3+ years of experience, I have developed and published
            numerous apps to Play Store and App Store. I have also developed
            websites using React framework by creating inch-perfect UIs.
          </Typography>
          <div className="divider"></div>
          <div className="contact-icon">
            <SiGmail
              size={"2rem"}
              className="hvr-icon"
              onMouseOver={({ target }) =>
                (target.style.color = "var(--primary-color)")
              }
              onMouseOut={({ target }) => (target.style.color = "white")}
              onClick={() => (window.location = "mailto:yafetretta@gmail.com")}
              cursor={"pointer"}
            />
            <BsGithub
              size={"3rem"}
              style={{ marginLeft: "1rem" }}
              onMouseOver={({ target }) =>
                (target.style.color = "var(--primary-color)")
              }
              onMouseOut={({ target }) => (target.style.color = "white")}
              onClick={() => {
                window.open("https://github.com/Memento-Morii", "_blank");
              }}
              cursor={"pointer"}
            />
            <FaLinkedinIn
              size={"2rem"}
              style={{ marginLeft: "1rem" }}
              onMouseOver={({ target }) =>
                (target.style.color = "var(--primary-color)")
              }
              onMouseOut={({ target }) => (target.style.color = "white")}
              onClick={() => {
                window.open(
                  "https://www.linkedin.com/in/yafet-r-alemayehu",
                  "_blank"
                );
              }}
              cursor={"pointer"}
            />
          </div>
          <PrimaryButton
            onclick={() => scroll("about-me")}
            name={"More about Me"}
            icon={<FaArrowRight className="btn-icon" />}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "40%",
          "@media screen and (max-width: 1000px)": {
            // Target sm breakpoint (600px)
            width: "100%",
          },
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={profileImg} className="profile-img" alt="" />
        {/* <Box
          sx={{
            // position: "absolute",
            padding: "1rem 0px",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // bottom: "7%",
            paddingX: "1rem",
            boxShadow: "1px 14px 41px 0px var(--bg-darkgray)",
            backgroundColor: "var(--bg-green)",
          }}
        >
          <div className="exp-txt">
            <h1>15+</h1>
            <h2>Projects</h2>
          </div>
          <div className="exp-txt">
            <h1>3+</h1>
            <h2>Years of Experience</h2>
          </div>
          <div className="exp-txt">
            <h1>10+</h1>
            <h2>Clients</h2>
          </div>
        </Box> */}
      </Box>
    </Box>
  );
};

export default LandingPage;
