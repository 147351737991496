import "./App.css";
import LandingPage from "./Pages/Landing Page/Landing Page";
import AboutMe from "./Pages/About Me/About Me";
import MyServices from "./Pages/My Services/My Services";
import MyPortfolio from "./Pages/My Portfolio/My Portfolio";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Blogs from "./Pages/Blogs/Blogs";
import Blog from "./Pages/Blog/Blog";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import { ImHome3 } from "react-icons/im";
import { GoPersonFill } from "react-icons/go";
import { TbBriefcaseFilled } from "react-icons/tb";
import { FaLaptopCode } from "react-icons/fa";
import { useEffect, useState } from "react";

const THEME = createTheme({
  typography: {
    fontFamily: `"Outfit", sans-serif`,
  },
});
const elementIds = ["landing-page", "about-me", "my-services", "my-portfolio"];
function App() {
  const [value, setValue] = useState(elementIds[0]);
  const OptionWidget = ({ icon, id }) => {
    let isCardSelected = id === value;
    return (
      <Box
        onClick={() => {
          setValue(id);
          scroll(id);
        }}
        sx={{
          marginTop: "15px",
          height: "2.5rem",
          width: "2.5rem",
          cursor: "pointer",
          bgcolor: isCardSelected
            ? "var(--primary-color)"
            : "var(--bg-lightgray)",
          display: "flex",
          borderRadius: "50%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon}
      </Box>
    );
  };
  const scroll = (id) => {
    const section = document.getElementById(id);
    if (id === elementIds[0]) {
      const offset = 200; // Adjust the offset as needed
      const rect = section.getBoundingClientRect();
      const scrollPosition = rect.top - offset;
      window.scrollTo({ top: scrollPosition, behavior: "smooth" });
    } else {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  useEffect(() => {
    function debounce(func, wait) {
      let timeout;

      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          timeout = null;
          func.apply(context, args);
        }, wait);
      };
    }

    const debouncedCallback = debounce((entries, observer) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setValue(entry.target.id);
      }
    }, 400);
    const observer = new IntersectionObserver(debouncedCallback);

    elementIds.forEach((id) =>
      // observe the refs that were applied to the sections
      observer.observe(document.getElementById(id))
    );
  }, []);
  return (
    <ThemeProvider theme={THEME}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <div className="App">
                <LandingPage />
                <AboutMe />
                <MyServices />
                <MyPortfolio />
                {/* <div className="email-line">
                <p className="email">yafetretta@gmail.com</p>
                <div className="line"></div>
              </div> */}
                <Box
                  sx={{
                    position: "fixed",
                    "@media screen and (max-width: 1000px)": {
                      display: "none",
                    },
                    right: 20,
                    top: "40%",
                  }}
                >
                  <OptionWidget id={"landing-page"} icon={<ImHome3 />} />
                  <OptionWidget id={"about-me"} icon={<GoPersonFill />} />
                  <OptionWidget
                    id={"my-services"}
                    icon={<TbBriefcaseFilled />}
                  />
                  <OptionWidget id={"my-portfolio"} icon={<FaLaptopCode />} />
                </Box>
              </div>
            }
          ></Route>
          <Route path="/blogs" element={<Blogs />}></Route>
          <Route path="/blog/:id" element={<Blog />}></Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
