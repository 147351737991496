// import eStotaUrl from "../Assets/estota.png";
const allPortfolio = [
  {
    title: "eStota",
    content:
      "The eStota app connects Ethiopian diasporas with their beloved ones and friends back home with gifts that make memories.",
    imgUrl: "images/portfolio/estota.png",
    githubUrl: null,
    playStoreUrl:
      "https://play.google.com/store/apps/details?id=com.seni.estota",
    appStoreUrl: "https://apps.apple.com/us/app/estota/id1600262907",
    technologies: ["Flutter", "Springboot"],
    link: null,
  },
  {
    title: "Metahu Delivery",
    content:
      "Metahu is a delivery company that mainly connects people with the best our city has to offer. ",
    imgUrl: "images/portfolio/metahu.png",
    githubUrl: null,
    playStoreUrl:
      "https://play.google.com/store/apps/details?id=metahuCustomer.app",
    appStoreUrl: "https://apps.apple.com/id/app/metahu-addis/id1640682242",
    technologies: ["Flutter"],
    link: null,
  },
  {
    title: "Abtlis",
    content:
      "Abtlist is an audio streaming application that offers an extensive library of religious content meticulously curated for Ethiopiain Orthodox religion",
    imgUrl: "images/portfolio/abtlis.webp",
    githubUrl: null,
    playStoreUrl:
      "https://play.google.com/store/apps/details?id=foundation.nabute.abtlis",
    appStoreUrl: null,
    technologies: ["Flutter", "Node.js"],
    link: null,
  },
  {
    title: "One Percent",
    content:
      "This is an investment app where you are able to return 1% of your investment daily.",
    imgUrl: "images/portfolio/one percent.png",
    githubUrl: null,
    playStoreUrl: null,
    appStoreUrl: null,
    technologies: ["React.js", "Flutter"],
    link: "https://1percentapp.com/",
  },
  {
    title: "Barber App",
    content:
      "This is an appointment app where you can choose time and date from your favorite hair saloon. You can also choose from different options of hair dressers in particular saloon",
    imgUrl: "images/portfolio/barber app.png",
    githubUrl: null,
    playStoreUrl: null,
    appStoreUrl: null,
    technologies: ["Flutter", "Firebase"],
    link: null,
  },
  {
    title: "Stock Portfolio App (Concept)",
    content:
      "This is a concept app for the upcoming stock market in Ethiopia. It allows to trade from different stocks in the market. It has also a page where you can keep track of your portfolio.",
    imgUrl: "images/portfolio/stock app.png",
    githubUrl: null,
    playStoreUrl: null,
    appStoreUrl: null,
    technologies: ["Flutter", "Firebase"],
    link: null,
  },
];

export default allPortfolio;
