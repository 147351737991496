import React from "react";
import "./Blogs.css";
// import { Grid } from "@mui/material";
// import BlogCard from "../../Components/Blog Card/Blog Card";
// import allBlogs from "../../Data/all_blogs";
import Navbar from "../../Components/Navbar/Navbar";
// import Zoom from "react-reveal/Zoom";
// import { Zoom } from "react-awesome-reveal";

const Blogs = () => {
  return (
    <div>
      <Navbar />
      <div className="blogs">
        <div className="blogs-header">
          {/* <img
            src="https://images.unsplash.com/photo-1488998427799-e3362cec87c3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
            className="img-header"
            alt=""
          /> */}
        </div>
        <h1>Coming Soon!</h1>
        {/*  <h1>Featured Blog</h1>
       <div className="featured-blog">
          <img
            src="https://images.unsplash.com/photo-1500989145603-8e7ef71d639e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1776&q=80"
            alt=""
            className="feat-img"
          />
          <div className="feat-desc">
            <h2>Lorem Ipsum Title</h2>
            <p>
              Tempor nulla exercitation ad do tempor cupidatat aliqua dolor nisi
              laboris officia aliquip fugiat nisi. Commodo in ullamco dolor
              fugiat eiusmod consectetur exercitation occaecat nostrud elit
              consequat ea. Sunt duis dolor cupidatat irure culpa labore
              proident aliqua. Id exercitation ut ut labore officia ea ex
              consectetur nulla. Amet labore do pariatur elit magna dolor enim.
            </p>
          </div>
        </div>
        <h1>Other Blogs</h1>

        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          rowSpacing={4}
          className="blog-grid"
          alignItems="center"
          // columnSpacing={20}
        >
          {allBlogs.map((blog, i) => (
            <Grid item md={6} lg={4} sm={12} xs={12}>
              <Zoom>
                <BlogCard blog={blog} />
              </Zoom>
            </Grid>
          ))}
        </Grid> */}
      </div>
    </div>
  );
};

export default Blogs;
