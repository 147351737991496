import React from "react";
import "./Service.css";
import { BsDatabase } from "react-icons/bs";
import { Box, Typography } from "@mui/material";

// import Fade from "react-reveal/Fade";

const Service = ({ serviceTitle, description, color }) => {
  return (
    <Box
      sx={{
        boxShadow: "1px 14px 41px 0px var(--bg-darkgray)",
        padding: "2rem",
        textAlign: "left",
        width: "60%",
        height: "80%",
        "@media screen and (max-width: 1200px)": {
          width: "90%",
        },
      }}
    >
      <BsDatabase size={35} fill="var(--primary-color)" />
      <Typography
        sx={{ fontWeight: "600", fontSize: "18px", marginTop: "20px" }}
      >
        {serviceTitle}
      </Typography>
      <Typography
        sx={{
          fontWeight: "300",
          fontSize: "14px",
          marginTop: "15px",
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default Service;
