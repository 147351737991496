import React from "react";
import "./Blog.css";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import allBlogs from "../../Data/all_blogs";

const Blog = () => {
  const { id } = useParams();
  // eslint-disable-next-line
  const newBlog = allBlogs.find((blog) => blog.id == id);
  useEffect(() => {
    document.title = newBlog.title;
  }, [newBlog]);
  return (
    <div className="blog-pg">
      <Navbar />
      <div className="title-ctn">
        <sub className="date-txt">Posted 20 January 2021</sub>
        <h2 className="blog-title">{newBlog.title}</h2>
      </div>

      <img src={newBlog.bgImgUrl} alt="" className="blog-img-ctn" />
      <div className="blog-content">
        {newBlog.content
          .sort((a, b) => a.index - b.index)
          .map((cnt, i) => {
            if (cnt.type === "text") {
              return <div className={cnt.className}>{cnt.value}</div>;
            } else {
              return <img className={cnt.className} src={cnt.value} alt="" />;
            }
          })}
      </div>
    </div>
  );
};

export default Blog;
