import React from "react";
import "./Skill.css";
import { Box, Typography } from "@mui/material";

const Skill = ({ isActive, icon, skillName }) => {
  return (
    <Box
      sx={{
        height: "8rem",
        width: "8rem",
        borderRadius: "50%",
        backgroundColor:
          isActive === true ? "var(--primary-color)" : "transparent",
        border: isActive === true ? null : "2px solid var(--bg-lightgray)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          color: isActive === true ? "var(--bg-green)" : "var(--primary-color)",
          fill: isActive === true ? "var(--bg-green)" : "var(--primary-color)",
        }}
      >
        {icon}
      </Box>

      {isActive === true ? (
        <Typography sx={{ color: "var(--bg-green)", fontWeight: "600" }}>
          {skillName}
        </Typography>
      ) : null}
    </Box>
  );
};

export default Skill;
