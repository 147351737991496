import { BsGithub, BsLink } from "react-icons/bs";
import { FaAppStore, FaGooglePlay } from "react-icons/fa";

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";

const OverlayContainer = styled(Box)({
  position: "relative",
  bgcolor: "white",
  height: "250px",
  width: "100%",
  "&:hover .overlay": {
    opacity: 1,
  },
});

const Image = styled("img")({
  height: "100%",
  objectFit: "cover",
});

const Overlay = styled(Box)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  opacity: 0,
  transition: "opacity 0.3s ease-in-out",
  color: "white",
});

const Portfolio = ({ portfolio }) => {
  return (
    <OverlayContainer>
      <Image src={portfolio.imgUrl} alt="Placeholder" />
      <Overlay className="overlay">
        <Typography variant="h6">.{portfolio.title}.</Typography>
        <Typography
          sx={{ fontWeight: "400", fontSize: "14px", paddingX: "8%" }}
        >
          {portfolio.content}
        </Typography>
        <Box display="flex" marginTop="10px">
          {portfolio.appStoreUrl !== null && (
            <FaAppStore
              size={"1.8rem"}
              style={{ marginRight: "1.5rem" }}
              onMouseOver={({ target }) => (target.style.color = "#d1fc04")}
              onMouseOut={({ target }) => (target.style.color = "white")}
              onClick={() => window.open(portfolio.appStoreUrl, "_blank")}
              cursor={"pointer"}
            />
          )}
          {portfolio.playStoreUrl !== null && (
            <FaGooglePlay
              size={"1.8rem"}
              style={{ marginRight: "1.5rem" }}
              onMouseOver={({ target }) => (target.style.color = "#d1fc04")}
              onMouseOut={({ target }) => (target.style.color = "white")}
              onClick={() => window.open(portfolio.playStoreUrl, "_blank")}
              cursor={"pointer"}
            />
          )}
          {portfolio.link !== null && (
            <BsLink
              size={"1.8rem"}
              style={{ marginRight: "1.5rem" }}
              onMouseOver={({ target }) => (target.style.color = "#d1fc04")}
              onMouseOut={({ target }) => (target.style.color = "white")}
              onClick={() => window.open(portfolio.link, "_blank")}
              cursor={"pointer"}
            />
          )}
          {portfolio.githubUrl !== null && (
            <BsGithub
              size={"1.8rem"}
              style={{ marginRight: "1.5rem" }}
              onMouseOver={({ target }) => (target.style.color = "#d1fc04")}
              onMouseOut={({ target }) => (target.style.color = "white")}
              cursor={"pointer"}
            />
          )}
        </Box>
        <Box display="flex" marginTop="20px">
          {portfolio.technologies.map((technology) => (
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "12px",
                color: "var(--primary-color)",
                marginRight: "15px",
              }}
            >
              {technology.toUpperCase()}
            </Typography>
          ))}
        </Box>
      </Overlay>
    </OverlayContainer>
  );
};

export default Portfolio;
