import { Box, Typography } from "@mui/material";
import React from "react";
import { IoBriefcase } from "react-icons/io5";

const ExperienceCard = ({ experience }) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "start",
      }}
    >
      <Box
        sx={{
          height: "150px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            backgroundColor: "var(--primary-color)",
          }}
        >
          <IoBriefcase />
        </Box>
        <Box
          sx={{
            width: "2px",
            height: "calc(100% - 35px)",
            backgroundColor: "var(--bg-lightgray)",
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          marginLeft: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            backgroundColor: "var(--bg-lightgray)",
            borderRadius: "15px",
            width: "140px",
          }}
        >
          <Typography sx={{ fontSize: "10px", padding: "6px 10px" }}>
            {experience.duration.toUpperCase()}
          </Typography>
        </Box>
        <Typography
          sx={{ fontSize: "14px", fontWeight: "600", marginY: "10px" }}
        >
          {experience.title} -{" "}
          <span style={{ fontWeight: "400", fontSize: "12px" }}>
            {experience.companyName}
          </span>
        </Typography>
        {experience.roles.map((role) => (
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              color: "lightgray",
              marginTop: "4px",
            }}
          >
            {role}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default ExperienceCard;
