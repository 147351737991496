// import eStotaUrl from "../Assets/estota.png";
const allBlogs = [
  {
    id: 1,
    isFeatured: false,
    title: "Blog Title One",
    description: "Exercitation incididunt magna exercitation adipisicing.",
    bgImgUrl:
      "https://images.unsplash.com/photo-1500989145603-8e7ef71d639e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1776&q=80s",
    createdAt: "Jan 30,2023",
    content: [
      {
        index: 0,
        value: "This is some content",
        className: "blog-h1",
        type: "text",
      },
    ],
  },
  {
    id: 2,
    isFeatured: true,
    title: "Blog Title Two",
    description: "Quis fugiat eiusmod mollit ea laboris ex in.",
    bgImgUrl:
      "https://images.unsplash.com/photo-1504805572947-34fad45aed93?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
    createdAt: "Jan 30,2023",
    content: [
      {
        index: 0,
        value: "Facading using inject",
        className: "blog-h1",
        type: "text",
      },
      {
        index: 2,
        value:
          "https://images.unsplash.com/photo-1664575602276-acd073f104c1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80",
        className: "blog-big-img",
        type: "img",
      },
      {
        index: 1,
        value:
          "As you already saw in the above examples, we were able to keep the component clean and dependency injection free. We no longer depended on the HttpClient or the ActivatedRoute directly in the component constructor.We instead created an iniectable function (thats how I like to call them) that takes care of the business logic, similar to how we would extract the logic into a Facade service which we would (still) inject in our component. Using the inject) function we no longer need any dependencies in our component.",
        className: "blog-p",
        type: "text",
      },
      {
        index: 4,
        value: "Facading using inject",
        className: "blog-p",
        type: "text",
      },
      {
        index: 5,
        value:
          "https://images.unsplash.com/photo-1661961112835-ca6f5811d2af?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1772&q=80",
        className: "blog-big-img",
        type: "img",
      },
      {
        index: 3,
        value:
          "As you already saw in the above examples, we were able to keep the component clean and dependency injection free. We no longer depended on the HttpClient or the ActivatedRoute directly in the component constructor.We instead created an iniectable function (thats how I like to call them) that takes care of the business logic, similar to how we would extract the logic into a Facade service which we would (still) inject in our component. Using the inject) function we no longer need any dependencies in our component.",
        className: "blog-p",
        type: "text",
      },
    ],
  },
  {
    id: 3,
    isFeatured: false,
    title: "Blog Title Three",
    description: "Exercitation incididunt magna exercitation adipisicing.",
    bgImgUrl:
      "https://images.unsplash.com/photo-1505740420928-5e560c06d30e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
    createdAt: "Jan 30,2023",
    content: [
      {
        index: 0,
        value: "This is some content",
        className: "blog-h1",
        type: "text",
      },
    ],
  },
  {
    id: 3,
    isFeatured: false,
    title: "Blog Title Three",
    description: "Exercitation incididunt magna exercitation adipisicing.",
    bgImgUrl:
      "https://images.unsplash.com/photo-1510070112810-d4e9a46d9e91?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1769&q=80",
    createdAt: "Jan 30,2023",
    content: [
      {
        index: 0,
        value: "This is some content",
        className: "blog-h1",
        type: "text",
      },
    ],
  },
  {
    id: 4,
    isFeatured: false,
    title: "Blog Title Four",
    description: "Exercitation incididunt magna exercitation adipisicing.",
    bgImgUrl:
      "https://images.unsplash.com/photo-1505682634904-d7c8d95cdc50?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
    createdAt: "Jan 30,2023",
    content: [
      {
        index: 0,
        value: "This is some content",
        className: "blog-h1",
        type: "text",
      },
    ],
  },
];

module.exports = allBlogs;
