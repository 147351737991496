import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Menu = ({ title, isLink, id }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/");
  };
  return isLink === false ? (
    <div
      className="menu"
      onClick={() => {
        if (location.pathname !== "/") {
          handleNavigation();
        }
        var element = document.getElementById(id);
        element.scrollIntoView({
          behavior: "smooth",
        });
      }}
    >
      {title}
    </div>
  ) : (
    <Link style={{ textDecoration: "none" }} to={"/blogs"} className="menu">
      {title}
    </Link>
  );
};

export default Menu;
