import React from "react";
// import { Splide, SplideSlide } from "@splidejs/react-splide";
// import { Swiper, SwiperSlide } x
import "./My Portfolio.css";

// import required modules
// import { Navigation } from "swiper";

import Portfolio from "../../Components/Portfolio/Portfolio";
import allPortfolio from "../../Data/all_portfolio";
import { Fade } from "react-awesome-reveal";
import CustomTitle from "../../Components/Custom Title";
import { Grid } from "@mui/material";

const MyPortfolio = () => {
  return (
    <div id="new-portfolio">
      <Fade>
        <CustomTitle
          id="my-portfolio"
          bgTitle="WORKS"
          firstTitle="MY"
          secondTitle="PORTFOLIO"
        />
      </Fade>
      <Grid
        sx={{ overflowY: "hidden" }}
        container
        marginTop="20px"
        marginBottom="60px"
        spacing={2}
      >
        {allPortfolio.map((portfolio, i) => (
          <Grid width={"100%"} item lg={4} md={6} sm={12}>
            <Portfolio portfolio={portfolio} />
          </Grid>
        ))}
      </Grid>
      {/* <Fade>
        <Swiper navigation={true} modules={[Navigation]} className="my-swiper">
          {allPortfolio.map((portfolio, i) => (
            <SwiperSlide>
              <Portfolio portfolio={portfolio} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Fade> */}
    </div>
  );
};

export default MyPortfolio;
