import { Box, Typography } from "@mui/material";
import React from "react";

const CustomTitle = ({ bgTitle, firstTitle, secondTitle, id }) => {
  return (
    <Box
      id={id}
      sx={{
        position: "relative",
        width: "100%",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Typography
        align="center"
        style={{
          maxHeight: "100%",
          padding: 0,
          fontWeight: "700",
          color: "var(--bg-lightgray)",
          textTransform: "capitalize",
          fontSize: "6vw",
          // width: "7vw",
          bgcolor: "green",
          letterSpacing: "10px",
        }}
      >
        {bgTitle}
      </Typography>
      <Box
        sx={{
          textAlign: "center",
          position: "absolute",
          top: "50%",
          transform: "translateY(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontWeight: "700",
            color: "white",
            textTransform: "capitalize",
            fontSize: "3vw",
          }}
        >
          {firstTitle}
        </Typography>
        <Typography
          sx={{
            marginLeft: "10px",
            fontWeight: "700",
            color: "var(--primary-color)",
            textTransform: "capitalize",
            fontSize: "3vw",
          }}
        >
          {secondTitle}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomTitle;
