import React from "react";
import "./Primary Button.css";

const PrimaryButton = ({ width, height, onclick, name, icon }) => {
  return (
    <div onClick={onclick} className="primary-button">
      <div className="bg-box"></div>
      <div className="btn-name">{name}</div>
      <div>{icon}</div>
    </div>
  );
};

export default PrimaryButton;
