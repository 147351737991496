import React from "react";
import "./About Me.css";
import Skill from "../../Components/Skill/Skill";
import { experiences, skills } from "../../Data/work_experiences";
import { Grid } from "@mui/material";
import { Fade } from "react-awesome-reveal";
import CustomTitle from "../../Components/Custom Title";
import ExperienceCard from "../../Components/Experience Card";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box } from "@mui/material";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  EffectCoverflow,
} from "swiper";
import { IoCloudDownloadSharp } from "react-icons/io5";
import PrimaryButton from "../../Components/Primary Button/Primary Button";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// install Swiper modules
SwiperCore.use([Autoplay, Pagination, Navigation, EffectCoverflow]);
const progressStyle = {
  strokeLinecap: "round",
  textSize: "16px",
  pathTransitionDuration: 0.5,
  pathColor: "var(--primary-color)",
  textColor: "var(--primary-color)",
  trailColor: "var(--bg-lightgray)",
  backgroundColor: "#3e98c7",
};
const handleDownload = () => {
  const fileUrl = `${process.env.PUBLIC_URL}/cv/my-cv.pdf`;
  const link = document.createElement("a");
  link.href = fileUrl;
  link.download = "Yafet Alemayehu's CV";
  link.click();
};

const AboutMe = () => {
  return (
    <Fade>
      <div id="new-about-me">
        <CustomTitle
          id="about-me"
          bgTitle="RESUME"
          firstTitle="ABOUT"
          secondTitle="ME"
        />
        <div className="my-exp">
          <p className="primary-two-txt">Professional Experience</p>
          <Grid
            container
            direction="row"
            rowSpacing={6}
            columnSpacing={0}
            marginTop={"30px"}
            textAlign={"start"}
          >
            {experiences.map((exp, i) => (
              <Grid item md={6} xs={12} lg={4}>
                <ExperienceCard key={i} experience={exp} />
              </Grid>
            ))}
          </Grid>
        </div>
        <Box display="flex" alignItems="center" justifyContent="center">
          <PrimaryButton
            name={"Download CV"}
            icon={<IoCloudDownloadSharp className="btn-icon" size={20} />}
            onclick={handleDownload}
          />
        </Box>

        <p className="primary-two-txt">Software Skills</p>
        <Swiper
          style={{
            width: "75%",
            marginTop: "40px",
          }}
          centeredSlides={true}
          slidesPerView={6}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          navigation={false}
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 2,
              spaceBetween: 12,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 32,
            },
            1336: {
              slidesPerView: 6,
              spaceBetween: 32,
            },
          }}
        >
          {skills.map((skill) => (
            <SwiperSlide>
              {({ isActive }) => (
                <Skill
                  key={skill.index}
                  isActive={isActive}
                  icon={skill.icon}
                  skillName={skill.name}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <p className="primary-two-txt">Languages</p>
        <Grid container direction="row">
          <Grid display="flex" justifyContent="end" width={100} item xs={4}>
            <div style={{ width: 100 }}>
              <CircularProgressbar
                styles={buildStyles(progressStyle)}
                value={100}
                text={"Amharic"}
              />
            </div>
          </Grid>
          <Grid display="flex" justifyContent="center" item xs={4}>
            <div style={{ width: 100 }}>
              <CircularProgressbar
                styles={buildStyles(progressStyle)}
                value={100}
                text={"English"}
              />
            </div>
          </Grid>
          <Grid display="flex" justifyContent="start" item xs={4}>
            <div style={{ width: 100 }}>
              <CircularProgressbar
                styles={buildStyles(progressStyle)}
                value={50}
                text={"Français"}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default AboutMe;
